import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor() {}

  public uploadAvatar(file: string, userId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const targetPath = file;
      const bucket = firebase.default
        .storage()
        .refFromURL(`gs://boook-1fed7/${userId}/avatar`);
      const bucketFile = bucket.child('avatar.jpeg');

      bucketFile.putString(targetPath, 'data_url').then(
        (data) => {
          data.ref.getDownloadURL().then((url) => {
            resolve(url);
          });
        },
        (err) => {
          reject(console.log(err));
        }
      );
    });
  }
}
