export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyArzgDrmPUx3rGR6ASWay6y9SMvKSyev-8',
    authDomain: 'boook-1fed7.firebaseapp.com',
    databaseURL: 'https://boook-1fed7.firebaseio.com',
    projectId: 'boook-1fed7',
    storageBucket: 'boook-1fed7.appspot.com',
    messagingSenderId: '619797605773',
  },
  url: 'https://us-central1-boook-1fed7.cloudfunctions.net/boook',
  urlLocal: 'http://localhost:5001/boook-1fed7/us-central1/boook',
  urlStorage: 'https://firebasestorage.googleapis.com/v0/b',
};
