import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CanActivate, Router } from '@angular/router';
import { IndexeddbService } from 'src/app/services/';
import { User, UserType } from '../models/user';

type Check = {
  user?:boolean,
  email?: string;
  checked: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private indexeddbService: IndexeddbService,
    private router: Router
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      Promise.all([this.checkUserType(), this.checkLogin()]).then(
        (test: Check[]) => {
          if (
            test[0].checked === true &&
            test[1].checked === true &&
            test[0].email === test[1].email
          ) {
            resolve(true);
          } else {
            console.log(test);
            if (test[0].user) {
              this.router.navigate(['/login/acces-error']);
              resolve(false);
            } else {
              this.router.navigate(['/login']);
              resolve(false);
            }
          }
        }
      );
    });
  }

  private checkLogin(): Promise<Check> {
    return new Promise((resolve) => {
      this.angularFireAuth.onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
          resolve({
            email: user.email,
            checked: true,
          } as Check);
        } else {
          resolve({
            user: true,
            checked: false,
          } as Check);
        }
      });
    });
  }

  private checkUserType(): Promise<Check> {
    return new Promise((resolve) => {
      this.indexeddbService.get('user').then((user: User) => {
        if (user?.type && user?.type!.toUpperCase() === "ADMIN") {
          resolve({
            email: user.email,
            checked: true,
          } as Check);
        } else {
          this.indexeddbService.remove('user');
          if (user) {
            resolve({
              user: true,
              checked: false,
            } as Check);
          } else {
            resolve({
              user: false,
              checked: false,
            } as Check);
          }
        }
      });
    });
  }
}
