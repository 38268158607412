import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { IndexeddbService } from 'src/app/services/indexeddb.service';
import { Login } from '../models/login';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private angularFireAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    private angularFireDatabase: AngularFireDatabase,
    private indexeddbService: IndexeddbService,
    private router: Router
  ) {}

  public signIn(login: Login) {
    return this.angularFireAuth.signInWithEmailAndPassword(
      login.email,
      login.password
    );
  }

  public async saveUser(user: User): Promise<string> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth
        .createUserWithEmailAndPassword(user.email, user.password as string)
        .then(async () => {
          this.angularFireAuth.currentUser.then(async (data: any) => {
            let userId = data.uid;
            this.sendVerificationMail()
              .then((res) => {
                user.password = '';
                this.saveUserToCollection(userId, user);
                resolve(userId);
              })
              .catch((err) => {
                reject(err);
              });
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public async sendVerificationMail() {
    return (
      (await this.angularFireAuth.currentUser) as any
    ).sendEmailVerification();
  }

  public saveUserToCollection(id: string, data: User) {
    return this.firestore.collection('users').doc(id).set(data);
  }

  public async signOut() {
    await this.angularFireDatabase.database.goOffline();
    await this.indexeddbService.clear();
    await this.angularFireAuth.signOut();
    this.router.navigate(['/login']);
  }
}
