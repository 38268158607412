import { Injectable } from '@angular/core';
import { openDB } from 'idb';

@Injectable({
  providedIn: 'root',
})
export class IndexeddbService {
  constructor() { }

  public openDB(dbname: string) {
    return openDB('boook', 1, {
      upgrade(db: any) {
        db.createObjectStore(dbname);
      },
    });
  }

  async setJson(key: string, val: JSON) {
    return (await this.openDB('tmp')).put('tmp', val, key);
  }

  async set(key: string, val: any) {
    return (await this.openDB('tmp')).put('tmp', val, key);
  }

  async get(key: string) {
    return (await this.openDB('tmp')).get('tmp', key);
  }

  //remove object in index db
  async remove(key: string) {
    return (await this.openDB('tmp')).delete('tmp', key);
  }

  async clear() {
    return (await this.openDB('tmp')).clear('tmp');
  }
}
