import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { concat } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private angularFirestore: AngularFirestore) {}

  //function : get user collection in firebase
  public async getCollectionUser(email: string): Promise<User> {
    return new Promise(async (resolve, reject) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            reject(null);
          } else {
            snapshot.forEach((doc) => {
              resolve({
                id: doc.id,
                ...(doc.data() as User),
              });
            });
          }
        })
        .catch((err) => console.log(err));
    });
  }

  /**
   * get user by email address
   * @param email user email
   * @returns promise user
   */
  public getUserByEmail(email: string): Promise<User> {
    return new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('email', '==', email)
        .get()
        .then((doc) => {
          doc.forEach((data) => {
            resolve(<User>{ id: data.id, ...data.data() });
          });
        });
    });
  }

  /**
   * get user by id
   * @param idUser user id
   * @returns
   */
  public getUserById(idUser: string): Promise<User> {
    return new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .doc(idUser)
        .get()
        .then((doc) => {
          resolve(<User>{ id: doc.id, ...doc.data() });
        });
    });
  }

  /**
   * Update an use exists
   */
  public updateUser(userId: string, user: Object): Promise<void> {
    return new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .doc(userId)
        .set(user)
        .then(() => {
          resolve();
        });
    });
  }
  /**
   * find users by keywords
   * @param key keywords
   * @returns users array
   */
  public findUserByKeywords(key: string): Promise<User[]> {
    const query1: Promise<User[]> = new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('nom', '==', key)
        .get()
        .then(async (data) => {
          const tabUser: User[] = [];
          await data.forEach((docUser) => {
            tabUser.push({ id: docUser.id, ...(docUser.data() as User) });
          });
          resolve(tabUser);
        });
    });

    const query2: Promise<User[]> = new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('prenom', '==', key)
        .get()
        .then(async (data) => {
          const tabUser: User[] = [];
          await data.forEach((docUser) => {
            tabUser.push({ id: docUser.id, ...(docUser.data() as User) });
          });
          resolve(tabUser);
        });
    });

    const query3: Promise<User[]> = new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('email', '==', key)
        .get()
        .then(async (data) => {
          const tabUser: User[] = [];
          await data.forEach((docUser) => {
            tabUser.push({ id: docUser.id, ...(docUser.data() as User) });
          });
          resolve(tabUser);
        });
    });
    const query4: Promise<User[]> = new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .where('pseudo', '==', key)
        .get()
        .then(async (data) => {
          const tabUser: User[] = [];
          await data.forEach((docUser) => {
            tabUser.push({ id: docUser.id, ...(docUser.data() as User) });
          });
          resolve(tabUser);
        });
    });
    return Promise.all([query1, query2, query3, query4]).then(
      ([data1, data2, data3, data4]) => {
        const seen = new Set();
        const arr: User[] = data1.concat(data2).concat(data3).concat(data4);
        const filteredArr = arr.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });

        return filteredArr;
      }
    );
  }

  public deleteUserById(idUser: string) {
    return this.angularFirestore.firestore
      .collection('users')
      .doc(idUser)
      .delete();
  }

  public getAllUsers(): Promise<User[]> {
    return new Promise((resolve) => {
      this.angularFirestore.firestore
        .collection('users')
        .orderBy('dateCreate', 'desc')
        .get()
        .then(async (docs) => {
          const users: User[] = [];
          await docs.forEach((user) => {
            users.push({ id: user.id, ...(user.data() as User) });
          });
          resolve(users);
        });
    });
  }
}
